///////////////////////////////////////////////////////////////
///
///  COMPONENT RESPONSIBLE FOR DISPLAYING DAILY MEETING VIEW
///
///////////////////////////////////////////////////////////////

import type { NextPage } from "next";
import React, { useEffect, useState } from "react";
///////////////////////////////////////////////////////////////
///
///  COMPONENT RESPONSIBLE FOR USER AUTHENTICATION USING AMPLIFY
///
///////////////////////////////////////////////////////////////

import { useRouter } from "next/router";
//AMPLIFY
import { Amplify, Auth } from "aws-amplify";
import {
  Image,
  View,
  useTheme,
  //ThemeProvider,
  //Theme,
} from "@aws-amplify/ui-react";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";

//STATE
import { authStore, utilityStore } from "@state/store";

//COGNITO FUNCTIONS
import { signUp, signIn } from "@cognito";

// STYLES AMPLIFY
import "@aws-amplify/ui-react/styles.css";
// MUI COMPONENTS
import {
  Link,
  Container,
  Box,
  Button,
  Stack,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

///////////////////////////////////////////////////////////////
///
///  DAILY VIEW COMPONENT
///
///////////////////////////////////////////////////////////////

const Authentication: NextPage = () => {
  // GLOBAL STATE
  const { isSignedIn, validSession, setIsSignedIn, isGuest } = authStore();
  const { keepMeSignedInToggle, setKeepMeSignedInToggle } = utilityStore();

  //STATE
  const [componentState, setComponentState] = useState<
    "signIn" | "signUp" | "resetPassword" | "confirmSignIn" | undefined
  >("signIn");

  //const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  ///////////////////////////////////////////////////////////////
  ///
  ///  ROUTER
  ///
  ///////////////////////////////////////////////////////////////
  const router = useRouter();

  const { uuid } = router.query;

  ///////////////////////////////////////////////////////////////
  ///
  ///  SERVICE HANDLERS SECTION
  ///
  ///////////////////////////////////////////////////////////////

  const services = {
    // CUSTOMIZED SIGN UP HANDLER
    async handleSignUp(formData: any) {
      console.log("SIGNING UP");
      const { username, password, attributes } = formData;
      const user = await signUp(username, password, attributes);

      return user;
    },

    // CUSTOMIZED SIGN UP VERIFICATION HANDLER
    async handleConfirmSignUp(data: any) {
      const { username, code } = data;
      const confirmed = await Auth.confirmSignUp(username, code);

      return confirmed;
    },

    // //CUSTOMIZED SIGN IN HANDLER
    async handleSignIn(formData: any) {
      const { username, password } = formData;
      const user = await signIn(username, password);

      if (!user) {
        setIsSignedIn(false);
        return;
      }
      // TYPE OF SIGN IN OTP / NOMFA
      if (user.preferredMFA === "NOMFA") {
        setIsSignedIn(true);
        return user;
      } else {
        setComponentState("confirmSignIn");
        return user;
      }
    },
    // CUSTOMIZED SIGN IN VERIFICATION HANDLER
    async handleConfirmSignIn(data: any) {
      const { user, code, mfaType } = data;
      const confirmed = await Auth.confirmSignIn(user, code, mfaType);
      if (!confirmed) {
        setIsSignedIn(false);
      } else {
        setIsSignedIn(true);
      }
      return confirmed;
    },
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  THEME
  ///
  ///////////////////////////////////////////////////////////////
  // const { tokens } = useTheme();
  // const theme: Theme = {
  //   name: "Auth Example Theme",
  //   tokens: {
  //     colors: {
  //       background: {
  //         primary: {
  //           value: "#ffffff",
  //         },
  //         secondary: {
  //           value: "#ffffff",
  //         },
  //       },
  //       button: {
  //         primary: { value: "red" },
  //       },

  //       font: {
  //         interactive: {
  //           value: tokens.colors.white.value,
  //         },
  //       },
  //       brand: {
  //         primary: {
  //           "10": tokens.colors.blue["100"],
  //         },
  //       },
  //     },
  //     components: {
  //       tabs: {
  //         item: {
  //           _focus: {
  //             color: {
  //               value: tokens.colors.white.value,
  //             },
  //           },
  //           _hover: {
  //             color: {
  //               value: tokens.colors.yellow["80"].value,
  //             },
  //           },
  //           _active: {
  //             color: {
  //               value: tokens.colors.white.value,
  //             },
  //           },
  //         },
  //       },
  //     },
  //   },
  // };

  ///////////////////////////////////////////////////////////////
  ///
  ///  AMPLIFY SUBCOMPONENTS STRUCTURE SECTION
  ///
  ///////////////////////////////////////////////////////////////
  const components = {
    Header() {
      const { tokens } = useTheme();
      const { toSignIn } = useAuthenticator();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          {componentState != "signIn" ? (
            <Button
              startIcon={<ArrowBackIosIcon fontSize="small" />}
              sx={{ position: "absolute", left: "1%", top: "2%" }}
              variant="outlined"
              color="secondary"
              onClick={() => {
                setComponentState("signIn");
                toSignIn();
              }}
            >
              Back
            </Button>
          ) : (
            <Box></Box>
          )}
          <Image
            alt="Visionable logo"
            src="/images/visionable-login-logo.svg"
          />
        </View>
      );
    },
    formFields() {
      return <div>test</div>;
    },

    SignIn: {
      Header() {
        //const { tokens } = useTheme();

        return (
          <Box>
            <Typography sx={{ paddingLeft: "1em" }} variant="h2">
              Sign in
            </Typography>
          </Box>
        );
      },

      Footer() {
        const { toResetPassword, toSignUp } = useAuthenticator();

        return (
          <Stack spacing={1}>
            <Stack direction={"row"}>
              <Typography sx={{ paddingLeft: "2em" }}>
                Forgot Password?
              </Typography>
              &nbsp;
              <Typography
                sx={{ textDecoration: "underline" }}
                onClick={() => {
                  toResetPassword(), setComponentState("resetPassword");
                }}
              >
                <Link sx={{ textDecoration: "underline" }}>Click Here</Link>
              </Typography>
            </Stack>
            <Stack direction={"row"}>
              <Typography sx={{ paddingLeft: "2em" }}>
                Need an account?
              </Typography>
              &nbsp;
              <Typography
                sx={{ textDecoration: "underline" }}
                onClick={() => {
                  router.push("/admin/signup");
                }}
              >
                <Link>Click Here</Link>
              </Typography>
            </Stack>
            <Stack sx={{ paddingLeft: "2em" }} direction={"row"}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={keepMeSignedInToggle}
                    onChange={() => {
                      handleSigninType();
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Keep me signed in"
              />
            </Stack>
          </Stack>
        );
      },
    },

    SignUp: {
      Header() {
        //const { tokens } = useTheme();

        return (
          <Box>
            <Typography sx={{ paddingLeft: "1em" }} variant="h2">
              Sign up
            </Typography>
          </Box>
        );
      },

      Footer() {
        const { toSignIn } = useAuthenticator();

        return (
          <Stack spacing={1}>
            <Stack direction={"row"}>
              <Typography sx={{ paddingLeft: "2em" }}>
                Have an account?
              </Typography>
              &nbsp;
              <Typography
                sx={{ textDecoration: "underline" }}
                onClick={() => {
                  toSignIn(), setComponentState("signIn");
                }}
              >
                <Link>Click Here</Link>
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        //const { tokens } = useTheme();
        return (
          <Box>
            <Typography variant="h2">Verification</Typography>
          </Box>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();
        return (
          <Stack spacing={1}>
            <Stack direction={"row"}>
              <Typography>Have an account?</Typography>
              &nbsp;
              <Typography
                sx={{ textDecoration: "underline" }}
                onClick={() => {
                  toSignIn(), setComponentState("signIn");
                }}
              >
                <Link>Click Here</Link>
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    SetupTOTP: {
      Header() {
        return (
          <Box>
            <Typography variant="h2">
              Setup Multi Factor Authentication
            </Typography>
          </Box>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();
        return (
          <Stack spacing={1}>
            <Stack direction={"row"}>
              <Typography>Have an account?</Typography>&nbsp;
              <Typography
                sx={{ textDecoration: "underline" }}
                onClick={() => {
                  toSignIn(), setComponentState("signIn");
                }}
              >
                <Link>Click Here</Link>
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    ConfirmSignIn: {
      Header() {
        //const { tokens } = useTheme();
        return (
          <>
            <Box>
              <Typography variant="h2">Enter code</Typography>
            </Box>
            <Box>
              <Typography variant="body1">
                For verification, please enter the code sent to your messages or
                via the authentication app.
              </Typography>
            </Box>
          </>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();

        return (
          <Stack spacing={1}>
            <Stack direction={"row"}>
              <Typography>Have an account?</Typography>&nbsp;
              <Typography
                sx={{ textDecoration: "underline" }}
                onClick={() => {
                  toSignIn(), setComponentState("signIn");
                }}
              >
                <Link>Click Here</Link>
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    ResetPassword: {
      Header() {
        //const { tokens } = useTheme();
        return (
          <Box>
            <Typography variant="h2">Forgot Password</Typography>
          </Box>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();
        return (
          <Stack spacing={1}>
            <Stack direction={"row"}>
              <Typography>Have an account?</Typography>
              &nbsp;
              <Typography
                sx={{ textDecoration: "underline" }}
                onClick={() => {
                  toSignIn(), setComponentState("signIn");
                }}
              >
                <Link>Click Here</Link>
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    ConfirmResetPassword: {
      Header() {
        //const { tokens } = useTheme();
        return (
          <Box>
            <Typography variant="h2">Reset Password</Typography>
          </Box>
        );
      },
      Footer() {
        const { toSignIn } = useAuthenticator();

        return (
          <Stack spacing={1}>
            <Stack direction={"row"}>
              <Typography>Have an account?</Typography>&nbsp;
              <Typography
                sx={{ textDecoration: "underline" }}
                onClick={() => {
                  toSignIn(), setComponentState("signIn");
                }}
              >
                <Link>Click Here</Link>
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  STRUCTURE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  const formFields = {
    signIn: {
      username: {
        type: "Email",
        labelHidden: false,
        label: "Email",
        placeholder: "",
      },
      password: {
        labelHidden: false,
        label: "Password",
        placeholder: "",
      },
    },
    signUp: {
      given_name: {
        labelHidden: false,
        label: "First Name",
        isRequired: true,
        placeholder: "",
        order: 1,
        hint: "Enter your first name",
      },
      family_name: {
        labelHidden: false,
        label: "Last Name",
        isRequired: true,
        placeholder: "",
        order: 2,
      },
      username: {
        type: "Email",
        labelHidden: false,
        label: "Email",
        isRequired: true,
        placeholder: "",
        order: 3,
      },
      password: {
        labelHidden: false,
        label: "Password",
        isRequired: true,
        placeholder: "",
        order: 4,
      },
      confirm_password: {
        labelHidden: false,
        label: "Confirm Password",
        isRequired: true,
        placeholder: "",
        order: 5,
      },
    },
    forceNewPassword: {
      password: {
        labelHidden: false,
        placeholder: "",
      },
    },
    resetPassword: {
      username: {
        type: "Email",
        label: "Please enter your email",
        labelHidden: false,
        placeholder: "",
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        labelHidden: false,
        label: "Enter Confirmation Code",
        placeholder: "",
        isRequired: true,
      },
      password: {
        labelHidden: false,
        placeholder: "",
      },
      confirm_password: {
        labelHidden: false,
        placeholder: "",
      },
    },
    setupTOTP: {
      QR: {
        totpIssuer: "Visionable",
        totpUsername: "amplify_qr_test_user",
      },
      confirmation_code: {
        labelHidden: false,
        label: "Verification Code",
        placeholder: "",
        isRequired: true,
      },
    },
    confirmSignIn: {
      confirmation_code: {
        labelHidden: false,
        label: "Verification Code",
        placeholder: "",
        isRequired: true,
      },
    },
    confirmSignUp: {
      confirmation_code: {
        labelHidden: false,
        label: "Enter Confirmation Code",
        placeholder: "",
        isRequired: true,
      },
    },
    // CUSTOMIZED SIGN
    confirmVerifyUser: {
      confirmation_code: {
        labelHidden: false,
        label: "Enter Confirmation Code",
        placeholder: "",
        isRequired: true,
      },
    },
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  FUNCTION  SECTION
  ///
  ///////////////////////////////////////////////////////////////

  // TOGGLE KEEP SIGNED IN AND CHOOSE APPROPRIATE STORAGE
  const handleSigninType = () => {
    if (keepMeSignedInToggle) {
      setKeepMeSignedInToggle(false);
      Amplify.configure({
        storage: sessionStorage,
      });
    } else {
      setKeepMeSignedInToggle(true);
      Amplify.configure({
        storage: localStorage,
      });
    }
  };

  ///////////////////////////////////////////////////////////////
  ///
  ///  LIFECYCLE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  // DETERMINES WHICH STORAGE TO USE BASED ON LOCALSTORAGE CONTENTS
  // IF HOSTED UI KEY EXISTS MEANS WE ARE USING LOCALSTORAGE AND THUS
  // USER IS RELYING ON LOCALSTORAGE AND THUS WANTS TO BEE KEEPT SIGNED IN
  useEffect(() => {
    Amplify.configure({
      storage:
        localStorage.getItem("amplify-signin-with-hostedUI") === null
          ? sessionStorage
          : localStorage,
    });
  }, []);

  // HANDLE INITIAL SIGN IN TOGGLE VALUES
  useEffect(() => {
    if (keepMeSignedInToggle) {
      Amplify.configure({
        storage: localStorage,
      });
    } else {
      Amplify.configure({
        storage: sessionStorage,
      });
    }
  }, []);

  // REDIRECT BASE ON SIGNED IN STATUS
  useEffect(() => {
    if (isSignedIn) {
      router.push("/meetings");
    } else {
      // isGuest
      //   ? router.push("/join/[uuid]", `/join/${uuid}`) :
      router.push("/authentication");
    }
  }, [isSignedIn]);

  // CHECK IF USER IS ALREADY SIGGNED IN WITH COGNITO
  useEffect(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: true,
    })
      .then((user) => {
        setIsSignedIn(true);
      })
      .catch((err) => {
        setIsSignedIn(false);
      });
  }, []);

  // IF INVALID SESSION LOGOUT
  useEffect(() => {
    if (!validSession) {
      router.push("/authentication");
    }
  }, [validSession, isGuest]);

  ///////////////////////////////////////////////////////////////
  ///
  ///  MAIN STRUCTURE SECTION
  ///
  ///////////////////////////////////////////////////////////////

  return (
    <Container
      maxWidth="sm"
      sx={{
        padding: "2%",
        width: "100%",
        minHeight: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "100%" }}>
        {/* <ThemeProvider theme={theme}> */}

        <Authenticator
          services={services}
          formFields={formFields}
          components={components}
          initialState={"signIn"}
        ></Authenticator>

        {/* </ThemeProvider> */}
      </Box>
    </Container>
  );
};

export default Authentication;

///////////////////////////////////////////////////////////////
///
///  EXTRA FUTURE FEATURES AND MISCELLANEOUS
///
///////////////////////////////////////////////////////////////

// validateFields(context, _event) {
//   const { formValues } = context;
//   const validators = [ passwordMatches ];
// this can contain any additional custom validators.

//   /**
//    * runValidators runs all validators in the given array.
//    * This resolves if there are no error, and rejects otherwise.
//    */
//   return runValidators(formValues, validators);
// },

// FormFields() {
//   const { toResetPassword } = useAuthenticator();
//   return (
//     <Stack>
//       {/* Re-use default `Authenticator.SignUp.FormFields` */}
//       <Authenticator.SignUp.fo />

//       {/* Append & require Terms & Conditions field to sign up  */}
//       <Stack direction={"row"}>
//         <Box>Need an account?</Box>
//         <Box onClick={toResetPassword}>
//           <Link>Click Here</Link>
//         </Box>
//       </Stack>
//     </Stack>
//   );
// },

///////////////////////////////////////////////////////////////
///
///  2FA
///
///////////////////////////////////////////////////////////////

// useEffect(async () => {
//   const user = await Auth.currentAuthenticatedUser();
//   const mfaType = await Auth.getPreferredMFA(user);
//   setMfa(mfaType === 'SOFTWARE_TOKEN_MFA');
//   setLoading(false);
// }, []);

// const setupTOTP = async () => {
//   const user = await Auth.currentAuthenticatedUser();
//   const { attributes: { email } } = user;

//   const code = await Auth.setupTOTP(user);
//   const issuer = "Visionable"; // TODO: ?
//   const str = `otpauth://totp/Visionable:${email}?secret=${code}&issuer=${issuer}`;
//   setQr(str);
// };

// const onMfaChange = async () => {
//   const user = await Auth.currentAuthenticatedUser();
//   setMfa(!mfa);

//   if (!mfa) {
//     setupTOTP();
//   } else {
//     Auth.setPreferredMFA(user, 'NOMFA');
//   }
// };

// const onTokenChange = (e) => {
//   setToken(e.target.value);
// };

// const checkToken = async () => {
//   const user = await Auth.currentAuthenticatedUser();

//   Auth.verifyTotpToken(user, token)
//     .then(() => {
//       Auth.setPreferredMFA(user, 'TOTP');
//       alert("Successfully enabled MFA");
//       setQr(null);
//     })
//     .catch((e) => {
//       console.log("ERROR", e);
//       alert("Error enabling MFA");
//     });
// };

// async handleSignIn(formData: any) {
//   console.log("LOGING IN");
//   const { username, password } = formData;
//   const user = await signIn(username, password);

//   if (!user) {
//     setIsSignedIn(false);
//     return;
//   } else {
//     console.log("USER LOGIN", user);
//     if (user.preferredMFA === "NOMFA") {
//       // TYPE OF SIGN IN OTP / NOMFA
//       const mfaStatus = await enforceMFA(user);
//       console.log("MFA STATUS", mfaStatus);

//       if (mfaStatus.status === "valid") {
//         console.log("valid");
//         setComponentState("confirmSignIn");
//         setIsSignedIn(true);
//         return user;
//       } else if (mfaStatus.status === "prompt") {
//         setCaution(true);
//         console.log("CAUTION", caution);
//         console.log("prompt");
//         setAccountMfa(mfaStatus.mfaPreferances.accountMFA);
//         setToggleAccountMfaSetup(true);
//         //setIsSignedIn(true);
//         return user;
//       } else {
//         console.log("invalid");
//         setIsSignedIn(false);
//         Auth.signOut(user);
//         return;
//       }
//     } else {
//       setComponentState("confirmSignIn");
//       return user;
//     }
//   }
// },
